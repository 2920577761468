/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category_category_list } from '../models/Category_category_list';
import type { Category_category_view } from '../models/Category_category_view';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoryService {

    /**
     * Retrieves the collection of Category resources.
     * Retrieves the collection of Category resources.
     * @returns Category_category_list Category collection
     * @throws ApiError
     */
    public static getCategoryCollection({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<Category_category_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/categories',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }

    /**
     * Retrieves a Category resource.
     * Retrieves a Category resource.
     * @returns Category_category_view Category resource
     * @throws ApiError
     */
    public static getCategoryItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<Category_category_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/categories/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
