/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionButton_jsonld_calendar_event_admin_view } from './ActionButton_jsonld_calendar_event_admin_view';
import type { Company_jsonld_calendar_event_admin_view } from './Company_jsonld_calendar_event_admin_view';
import type { User_jsonld_calendar_event_admin_view } from './User_jsonld_calendar_event_admin_view';

export type CalendarEvent_jsonld_calendar_event_admin_view = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly uuid?: string;
    aagjeId?: number | null;
    title?: string;
    description?: string;
    startDate?: string | null;
    endDate?: string | null;
    images?: Array<string>;
    actionButtons?: Array<ActionButton_jsonld_calendar_event_admin_view>;
    status?: CalendarEvent_jsonld_calendar_event_admin_view.status;
    upsell?: CalendarEvent_jsonld_calendar_event_admin_view.upsell;
    acceptedAt?: string | null;
    acceptedBy?: User_jsonld_calendar_event_admin_view | null;
    company?: Company_jsonld_calendar_event_admin_view;
    readonly primaryImage?: string | null;
};

export namespace CalendarEvent_jsonld_calendar_event_admin_view {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }

    export enum upsell {
        NONE = 'none',
        BASIC = 'basic',
        PREMIUM = 'premium',
        HIGHLIGHT = 'highlight',
        CALL_ADVICE = 'call_advice',
    }


}

