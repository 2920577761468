/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyCategory_company_category_list } from '../models/CompanyCategory_company_category_list';
import type { CompanyCategory_company_category_view } from '../models/CompanyCategory_company_category_view';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyCategoriesService {

    /**
     * Retrieves the collection of CompanyCategory resources.
     * Retrieves the collection of CompanyCategory resources.
     * @returns CompanyCategory_company_category_list CompanyCategory collection
     * @throws ApiError
     */
    public static getCompanyCategoryCollection({
        page = 1,
        itemsPerPage = 30,
        regionCode,
        regionCodeArray,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        regionCode?: string,
        regionCodeArray?: Array<string>,
    }): CancelablePromise<Array<CompanyCategory_company_category_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/company_categories',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'region.code': regionCode,
                'region.code[]': regionCodeArray,
            },
        });
    }

    /**
     * Retrieves a CompanyCategory resource.
     * Retrieves a CompanyCategory resource.
     * @returns CompanyCategory_company_category_view CompanyCategory resource
     * @throws ApiError
     */
    public static getCompanyCategoryItem({
        id,
    }: {
        /**
         * Resource identifier
         */
        id: string,
    }): CancelablePromise<CompanyCategory_company_category_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/company_categories/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
