/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionButton_calendar_event_create } from './ActionButton_calendar_event_create';

export type CalendarEvent_calendar_event_create = {
    title?: string;
    description?: string;
    startDate?: string | null;
    endDate?: string | null;
    images?: Array<string>;
    actionButtons?: Array<ActionButton_calendar_event_create>;
    upsell?: CalendarEvent_calendar_event_create.upsell;
    company?: string;
};

export namespace CalendarEvent_calendar_event_create {

    export enum upsell {
        NONE = 'none',
        BASIC = 'basic',
        PREMIUM = 'premium',
        HIGHLIGHT = 'highlight',
        CALL_ADVICE = 'call_advice',
    }


}

