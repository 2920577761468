/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Offer_jsonld_offer_create = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    title?: string;
    campaignTitle?: string;
    startDate?: string | null;
    expirationDate?: string | null;
    url?: string;
    categories?: Array<string>;
    images?: Array<string> | null;
    description?: string;
    terms?: string;
    upsell?: Offer_jsonld_offer_create.upsell;
    company?: string;
    readonly primaryImage?: string | null;
};

export namespace Offer_jsonld_offer_create {

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_ADVICE = 'call_advice',
    }


}

