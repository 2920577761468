/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Registration_registration_admin_list } from '../models/Registration_registration_admin_list';
import type { Registration_registration_admin_view } from '../models/Registration_registration_admin_view';
import type { Registration_registration_admin_view_region_embed } from '../models/Registration_registration_admin_view_region_embed';
import type { User_user_admin_list } from '../models/User_user_admin_list';
import type { User_user_admin_update } from '../models/User_user_admin_update';
import type { User_user_admin_view } from '../models/User_user_admin_view';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserAdministrationService {

    /**
     * Retrieves a collection of new user registrations
     * Retrieves the collection of Registration resources.
     * @returns Registration_registration_admin_list Registration collection
     * @throws ApiError
     */
    public static adminGetRegistrationCollection({
        page = 1,
        itemsPerPage = 30,
        name,
        email,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        name?: string,
        email?: string,
    }): CancelablePromise<Array<Registration_registration_admin_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/registrations',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'name': name,
                'email': email,
            },
        });
    }

    /**
     * Retrieves a user registration resource
     * Retrieves a Registration resource.
     * @returns Registration_registration_admin_view_region_embed Registration resource
     * @throws ApiError
     */
    public static adminGetRegistrationItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<Registration_registration_admin_view_region_embed> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/registrations/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Accept user registration
     * Replaces the Registration resource.
     * @returns Registration_registration_admin_view Registration resource updated
     * @throws ApiError
     */
    public static adminAcceptRegistrationItem({
        code,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        code: string,
        /**
         * The updated Registration resource
         */
        requestBody: any,
    }): CancelablePromise<Registration_registration_admin_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/registrations/{code}/accept',
            path: {
                'code': code,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Reject user registration
     * Removes the Registration resource.
     * @returns void
     * @throws ApiError
     */
    public static adminRejectRegistrationItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/registrations/{code}/reject',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of User resources.
     * Retrieves the collection of User resources.
     * @returns User_user_admin_list User collection
     * @throws ApiError
     */
    public static adminGetUserCollection({
        page = 1,
        itemsPerPage = 30,
        name,
        email,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        name?: string,
        email?: string,
    }): CancelablePromise<Array<User_user_admin_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/users',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'name': name,
                'email': email,
            },
        });
    }

    /**
     * Retrieves a User resource.
     * Retrieves a User resource.
     * @returns User_user_admin_view User resource
     * @throws ApiError
     */
    public static adminGetUserItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<User_user_admin_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the User resource.
     * Replaces the User resource.
     * @returns User_user_admin_view User resource updated
     * @throws ApiError
     */
    public static adminPutUserItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated User resource
         */
        requestBody: User_user_admin_update,
    }): CancelablePromise<User_user_admin_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the User resource.
     * Removes the User resource.
     * @returns void
     * @throws ApiError
     */
    public static adminDeleteUserItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
