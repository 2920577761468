/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CalendarEvent_jsonld_calendar_events_admin_list = {
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly uuid?: string;
    title?: string;
    description?: string;
    startDate?: string | null;
    endDate?: string | null;
    images?: Array<string>;
    status?: CalendarEvent_jsonld_calendar_events_admin_list.status;
    upsell?: CalendarEvent_jsonld_calendar_events_admin_list.upsell;
    company?: string;
    readonly primaryImage?: string | null;
};

export namespace CalendarEvent_jsonld_calendar_events_admin_list {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }

    export enum upsell {
        NONE = 'none',
        BASIC = 'basic',
        PREMIUM = 'premium',
        HIGHLIGHT = 'highlight',
        CALL_ADVICE = 'call_advice',
    }


}

