/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Day_jsonld_company_admin_view_region_embed } from './Day_jsonld_company_admin_view_region_embed';

export type OpeningTimes_jsonld_company_admin_view_region_embed = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    type?: OpeningTimes_jsonld_company_admin_view_region_embed.type;
    monday?: Day_jsonld_company_admin_view_region_embed;
    tuesday?: Day_jsonld_company_admin_view_region_embed;
    wednesday?: Day_jsonld_company_admin_view_region_embed;
    thursday?: Day_jsonld_company_admin_view_region_embed;
    friday?: Day_jsonld_company_admin_view_region_embed;
    saturday?: Day_jsonld_company_admin_view_region_embed;
    sunday?: Day_jsonld_company_admin_view_region_embed;
    remarks?: string;
};

export namespace OpeningTimes_jsonld_company_admin_view_region_embed {

    export enum type {
        NONE = 'none',
        ALWAYS_OPEN = 'always_open',
        SPECIFIC = 'specific',
    }


}

