/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionButton_company_update } from './ActionButton_company_update';
import type { KnownFor_company_update } from './KnownFor_company_update';
import type { OpeningTimes_company_update } from './OpeningTimes_company_update';

export type Company_company_update = {
    address?: string;
    zipCode?: string;
    images?: Array<string>;
    description?: string;
    knownFor?: KnownFor_company_update;
    oneLiner?: string;
    openingTimes?: OpeningTimes_company_update;
    actionButtons?: Array<ActionButton_company_update>;
    website?: string;
    facebook?: string;
    instagram?: string;
    linkedIn?: string;
    pinterest?: string;
    twitter?: string;
    youtube?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;
    upsell?: Company_company_update.upsell;
    categories?: Array<string>;
    name?: string;
    location?: string;
};

export namespace Company_company_update {

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_UPSELL = 'call_upsell',
        CALL_ADVICE = 'call_advice',
    }


}

