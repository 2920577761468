/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Activation } from '../models/Activation';
import type { Credentials } from '../models/Credentials';
import type { PasswordReset } from '../models/PasswordReset';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { Profile } from '../models/Profile';
import type { Registration } from '../models/Registration';
import type { User_user_view } from '../models/User_user_view';
import type { User_user_view_user_view_self } from '../models/User_user_view_user_view_self';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Get your own information
     * Returns your own information
     * @returns User_user_view_user_view_self User resource
     * @throws ApiError
     */
    public static meUserItem(): CancelablePromise<User_user_view_user_view_self> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/me',
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Register a new user
     * Registers a new user
     * @returns string Created
     * @throws ApiError
     */
    public static registerRegistrationCollection({
        requestBody,
    }: {
        /**
         * The new Registration resource
         */
        requestBody: Registration,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }

    /**
     * Activate a new user
     * Activates a new user
     * @returns any Created
     * @throws ApiError
     */
    public static registerActivationCollection({
        requestBody,
    }: {
        /**
         * The new Activation resource
         */
        requestBody: Activation,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/register/activate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }

    /**
     * Request a password reset
     * Generate a password reset token and e-mail the code/link to the user
     * @returns string Created
     * @throws ApiError
     */
    public static passwordresetRequestPasswordResetRequestCollection({
        requestBody,
    }: {
        /**
         * The new PasswordResetRequest resource
         */
        requestBody: PasswordResetRequest,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/reset_password/request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Reset password
     * Reset the password to the given password, using a valid token
     * @returns string Created
     * @throws ApiError
     */
    public static passwordresetResetPasswordResetCollection({
        requestBody,
    }: {
        /**
         * The new PasswordReset resource
         */
        requestBody: PasswordReset,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/reset_password/reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Update your credentials
     * Update your credentials (email and/or password)
     * @returns any Ok
     * @throws ApiError
     */
    public static registerCredentialsCollection({
        requestBody,
    }: {
        /**
         * The new Credentials resource
         */
        requestBody: Credentials,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/update_credentials',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                403: `Invalid password`,
            },
        });
    }

    /**
     * Update your personal profile
     * Updates your personal profile
     * @returns string Ok
     * @throws ApiError
     */
    public static registerProfileCollection({
        requestBody,
    }: {
        /**
         * The new Profile resource
         */
        requestBody: Profile,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/update_profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
            },
        });
    }

    /**
     * Retrieves a User resource.
     * Retrieves a User resource.
     * @returns User_user_view User resource
     * @throws ApiError
     */
    public static getUserItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<User_user_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
