/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Offer_offer_view = {
    readonly uuid?: string;
    title: string;
    campaignTitle?: string;
    startDate: string | null;
    expirationDate: string | null;
    url?: string;
    categories?: Array<string>;
    images?: Array<string> | null;
    description: string;
    terms?: string;
    status?: Offer_offer_view.status;
    upsell?: Offer_offer_view.upsell;
    company?: string;
    readonly primaryImage?: string | null;
};

export namespace Offer_offer_view {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        RETRACTED = 'retracted',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_ADVICE = 'call_advice',
    }


}

