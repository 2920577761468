/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEvent_calendar_event_admin_view } from '../models/CalendarEvent_calendar_event_admin_view';
import type { CalendarEvent_calendar_event_update } from '../models/CalendarEvent_calendar_event_update';
import type { CalendarEvent_calendar_events_admin_list } from '../models/CalendarEvent_calendar_events_admin_list';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalendarEventAdministrationService {

    /**
     * Retrieves the collection of CalendarEvent resources.
     * Retrieves the collection of CalendarEvent resources.
     * @returns CalendarEvent_calendar_events_admin_list CalendarEvent collection
     * @throws ApiError
     */
    public static adminGetCalendarEventCollection({
        page = 1,
        itemsPerPage = 30,
        orderTitle,
        orderStartDate,
        orderEndDate,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        orderTitle?: 'asc' | 'desc',
        orderStartDate?: 'asc' | 'desc',
        orderEndDate?: 'asc' | 'desc',
    }): CancelablePromise<Array<CalendarEvent_calendar_events_admin_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/calendar_events',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'order[title]': orderTitle,
                'order[startDate]': orderStartDate,
                'order[endDate]': orderEndDate,
            },
        });
    }

    /**
     * View event
     * Retrieves a CalendarEvent resource.
     * @returns CalendarEvent_calendar_event_admin_view CalendarEvent resource
     * @throws ApiError
     */
    public static adminGetCalendarEventItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<CalendarEvent_calendar_event_admin_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/calendar_events/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Edit confirmed event
     * Replaces the CalendarEvent resource.
     * @returns CalendarEvent_calendar_event_admin_view CalendarEvent resource updated
     * @throws ApiError
     */
    public static adminPutCalendarEventItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated CalendarEvent resource
         */
        requestBody: CalendarEvent_calendar_event_update,
    }): CancelablePromise<CalendarEvent_calendar_event_admin_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/calendar_events/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Accept Calendar Event
     * Replaces the CalendarEvent resource.
     * @returns CalendarEvent_calendar_event_admin_view CalendarEvent resource updated
     * @throws ApiError
     */
    public static adminAcceptCalendarEventItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated CalendarEvent resource
         */
        requestBody: any,
    }): CancelablePromise<CalendarEvent_calendar_event_admin_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/calendar_events/{uuid}/accept',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Get event image file
     * Retrieves a CalendarEvent resource.
     * @returns binary image
     * @throws ApiError
     */
    public static adminImageCalendarEventItem({
        id,
        uuid,
    }: {
        id: number,
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/calendar_events/{uuid}/{id}',
            path: {
                'id': id,
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
