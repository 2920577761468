/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JWTAuth } from '../models/JWTAuth';
import type { JWTRefresh } from '../models/JWTRefresh';
import type { JWTResponse } from '../models/JWTResponse';
import type { SessionLogin } from '../models/SessionLogin';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthenticationService {

    /**
     * Refresh the JWT using a refresh token
     * Refresh the JWT using a refresh token
     * @returns JWTResponse Refresh successful
     * @throws ApiError
     */
    public static jwtRefresh({
        requestBody,
    }: {
        /**
         * Refresh token
         */
        requestBody: JWTRefresh,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/jwt/refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `An authentication exception occurred: incorrect or expired refresh token`,
            },
        });
    }

    /**
     * Log in and create a JWT
     * Log in and create a JWT
     * @returns JWTResponse Login successful
     * @throws ApiError
     */
    public static jwtToken({
        requestBody,
    }: {
        /**
         * Credentials
         */
        requestBody: JWTAuth,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/jwt/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `Invalid credentials: user does not exist or password is incorrect`,
            },
        });
    }

    /**
     * Log in and set a session cookie
     * Log in and set a session cookie
     * @returns JWTResponse Login successful
     * @throws ApiError
     */
    public static sessionLogin({
        requestBody,
    }: {
        /**
         * Credentials
         */
        requestBody: SessionLogin,
    }): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/session/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `Invalid credentials: user does not exist or password is incorrect`,
            },
        });
    }

    /**
     * Logout from session
     * Logout from session
     * @returns JWTResponse Logout successful
     * @throws ApiError
     */
    public static sessionLogout(): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/session/logout',
        });
    }

}
