import { Company_company_list } from 'generated/api';
import { useCompanies } from 'utils/api';
import { useUser } from 'hooks/UserContext';
import React, { ReactNode, createContext, useContext, useEffect, useCallback, useMemo } from 'react';

interface ActiveCompanyContextType {
  activeCompany?: Company_company_list;
  refreshCompanies: () => Promise<void>;
  setActiveCompanyUuid: (uuid?: string) => void;
}

const initialState = {
  activeCompany: undefined,
  // eslint-disable-next-line unicorn/no-useless-undefined
  refreshCompanies: async () => {
    //init
  },
  setActiveCompanyUuid: () => {
    // init
  },
};

const ActiveCompanyContext = createContext<ActiveCompanyContextType>(initialState);

interface Properties {
  activeCompanyUuid: Company_company_list['uuid'];
  children: ReactNode;
  setActiveCompanyUuid: (newValue: Company_company_list['uuid']) => void;
}

export const ActiveCompanyProvider = ({
  activeCompanyUuid,
  children,
  setActiveCompanyUuid,
}: Properties): JSX.Element => {
  const { user } = useUser();
  const { data: companies, refetch } = useCompanies({ enabled: !!user });

  const activeCompany = useMemo(
    () => companies?.find(({ uuid }) => uuid === activeCompanyUuid),
    [activeCompanyUuid, companies]
  );

  useEffect(() => {
    if (!user || !companies || companies.length === 0) {
      return;
    }

    const hasCurrentActiveCompany = companies.some(({ uuid }) => activeCompanyUuid === uuid);

    if (!hasCurrentActiveCompany) {
      const [{ uuid }] = companies;
      setActiveCompanyUuid(uuid);
    }
  }, [activeCompanyUuid, companies, setActiveCompanyUuid, user]);

  const refreshCompanies = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ActiveCompanyContext.Provider value={{ activeCompany, refreshCompanies, setActiveCompanyUuid }}>
      {children}
    </ActiveCompanyContext.Provider>
  );
};

export const useActiveCompany = () => useContext(ActiveCompanyContext);
