/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Offer_jsonld_offer_admin_list = {
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly uuid?: string;
    title?: string;
    campaignTitle?: string;
    startDate?: string | null;
    expirationDate?: string | null;
    status?: Offer_jsonld_offer_admin_list.status;
    readonly primaryImage?: string | null;
};

export namespace Offer_jsonld_offer_admin_list {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        RETRACTED = 'retracted',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }


}

