/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company_company_admin_list_region_embed } from '../models/Company_company_admin_list_region_embed';
import type { Company_company_admin_view } from '../models/Company_company_admin_view';
import type { Company_company_admin_view_region_embed } from '../models/Company_company_admin_view_region_embed';
import type { Company_company_update } from '../models/Company_company_update';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyAdministrationService {

    /**
     * Retrieves the collection of Company resources.
     * Retrieves the collection of Company resources.
     * @returns Company_company_admin_list_region_embed Company collection
     * @throws ApiError
     */
    public static adminGetCompanyCollection({
        page = 1,
        itemsPerPage = 30,
        name,
        regionCode,
        regionCodeArray,
        status,
        statusArray,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        name?: string,
        regionCode?: string,
        regionCodeArray?: Array<string>,
        status?: string,
        statusArray?: Array<string>,
    }): CancelablePromise<Array<Company_company_admin_list_region_embed>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/companies',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'name': name,
                'region.code': regionCode,
                'region.code[]': regionCodeArray,
                'status': status,
                'status[]': statusArray,
            },
        });
    }

    /**
     * Retrieves a Company resource.
     * Retrieves a Company resource.
     * @returns Company_company_admin_view_region_embed Company resource
     * @throws ApiError
     */
    public static adminGetCompanyItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<Company_company_admin_view_region_embed> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/companies/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Edit confirmed company
     * Replaces the Company resource.
     * @returns Company_company_admin_view Company resource updated
     * @throws ApiError
     */
    public static adminPutCompanyItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated Company resource
         */
        requestBody: Company_company_update,
    }): CancelablePromise<Company_company_admin_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/companies/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Accept Company
     * Replaces the Company resource.
     * @returns Company_company_admin_view Company resource updated
     * @throws ApiError
     */
    public static adminAcceptCompanyItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated Company resource
         */
        requestBody: any,
    }): CancelablePromise<Company_company_admin_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/companies/{uuid}/accept',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Get company image file
     * Retrieves a Company resource.
     * @returns binary image
     * @throws ApiError
     */
    public static adminImageCompanyItem({
        id,
        uuid,
    }: {
        id: number,
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/companies/{uuid}/{id}',
            path: {
                'id': id,
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
