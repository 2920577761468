/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Day_jsonld_company_update } from './Day_jsonld_company_update';

export type OpeningTimes_jsonld_company_update = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    type?: OpeningTimes_jsonld_company_update.type;
    monday?: Day_jsonld_company_update;
    tuesday?: Day_jsonld_company_update;
    wednesday?: Day_jsonld_company_update;
    thursday?: Day_jsonld_company_update;
    friday?: Day_jsonld_company_update;
    saturday?: Day_jsonld_company_update;
    sunday?: Day_jsonld_company_update;
    remarks?: string;
};

export namespace OpeningTimes_jsonld_company_update {

    export enum type {
        NONE = 'none',
        ALWAYS_OPEN = 'always_open',
        SPECIFIC = 'specific',
    }


}

