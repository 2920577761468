/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ActionButton_calendar_event_update = {
    name?: string;
    url?: string;
    type?: ActionButton_calendar_event_update.type;
    new_tab?: boolean;
};

export namespace ActionButton_calendar_event_update {

    export enum type {
        AANMELDEN = 'aanmelden',
        BEKIJK_AANBOD = 'bekijk-aanbod',
        BEKIJK_AANKOMENDE_EVENTS = 'bekijk-aankomende events',
        BEKIJK_ACTIES = 'bekijk-acties',
        BEKIJK_LESAANBOD = 'bekijk-lesaanbod',
        BEKIJK_MENUKAART = 'bekijk-menukaart',
        BEKIJK_ONS_ASSORTIMENT = 'bekijk-ons-assortiment',
        BEKIJK_ONZE_COLLECTIE = 'bekijk-onze-collectie',
        BEKIJK_OPENINGSTIJDEN = 'bekijk-openingstijden',
        BEKIJK_PRIJZEN = 'bekijk-prijzen',
        BEKIJK_PROGRAMMA = 'bekijk-programma',
        BEKIJK_TARIEVEN = 'bekijk-tarieven',
        BEKIJK_VACATURES = 'bekijk-vacatures',
        BEKIJK_WORKSHOP = 'bekijk-workshop',
        BEKIJK_WORKSHOPS = 'bekijk-workshops',
        BROCHURE = 'brochure',
        CONTACT_OPNEMEN = 'contact-opnemen',
        EMAIL = 'email',
        GRATIS_PROEFLES = 'gratis-proefles',
        INSCHRIJVEN = 'inschrijven',
        KOOP_KAARTJES = 'koop-kaartjes',
        MAAK_AFSPRAAK = 'maak-afspraak',
        NAAR_WEBSHOP = 'naar-webshop',
        NAAR_WEBSITE = 'naar-website',
        OFFERTE_AANVRAGEN = 'offerte-aanvragen',
        PROEFLES_AANVRAGEN = 'proefles-aanvragen',
        RESERVEER_NU = 'reserveer-nu',
        TELEFOONNUMMER = 'telefoonnummer',
        WORD_NU_LID = 'word-nu-lid',
        WORD_VRIJWILLIGER = 'word-vrijwilliger',
    }


}

