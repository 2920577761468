/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Category_offer_admin_view_category_embed } from './Category_offer_admin_view_category_embed';
import type { Company_offer_admin_view_category_embed } from './Company_offer_admin_view_category_embed';
import type { User_offer_admin_view_category_embed } from './User_offer_admin_view_category_embed';

export type Offer_offer_admin_view_category_embed = {
    readonly uuid?: string;
    title?: string;
    campaignTitle?: string;
    startDate?: string | null;
    expirationDate?: string | null;
    url?: string;
    categories?: Array<Category_offer_admin_view_category_embed>;
    images?: Array<string> | null;
    description?: string;
    terms?: string;
    status?: Offer_offer_admin_view_category_embed.status;
    upsell?: Offer_offer_admin_view_category_embed.upsell;
    acceptedAt?: string | null;
    acceptedBy?: User_offer_admin_view_category_embed | null;
    company?: Company_offer_admin_view_category_embed;
    readonly primaryImage?: string | null;
};

export namespace Offer_offer_admin_view_category_embed {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        RETRACTED = 'retracted',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_ADVICE = 'call_advice',
    }


}

