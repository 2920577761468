/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AagjeSearchCompaniesResult } from '../models/AagjeSearchCompaniesResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AagjeService {

    /**
     * Search Aagje for companies
     * Search Aagje for companies
     * @returns AagjeSearchCompaniesResult Search Aagje for companies
     * @throws ApiError
     */
    public static aagjeSearchCompanies({
        region,
        query,
    }: {
        /**
         * Region code
         */
        region: any,
        /**
         * Search query
         */
        query: any,
    }): CancelablePromise<AagjeSearchCompaniesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/aagje/search_companies',
            query: {
                'region': region,
                'query': query,
            },
            errors: {
                422: `Search failed due to incorrect region or Aagje error`,
            },
        });
    }

}
