/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Region_region_list } from '../models/Region_region_list';
import type { Region_region_view } from '../models/Region_region_view';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RegionService {

    /**
     * Retrieves the collection of Region resources.
     * Retrieves the collection of Region resources.
     * @returns Region_region_list Region collection
     * @throws ApiError
     */
    public static getRegionCollection({
        page = 1,
        itemsPerPage = 30,
        name,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        name?: string,
    }): CancelablePromise<Array<Region_region_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/regions',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'name': name,
            },
        });
    }

    /**
     * Retrieves a Region resource.
     * Retrieves a Region resource.
     * @returns Region_region_view Region resource
     * @throws ApiError
     */
    public static getRegionItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<Region_region_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/regions/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
