/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Day_company_admin_view_region_embed } from './Day_company_admin_view_region_embed';

export type OpeningTimes_company_admin_view_region_embed = {
    type?: OpeningTimes_company_admin_view_region_embed.type;
    monday?: Day_company_admin_view_region_embed;
    tuesday?: Day_company_admin_view_region_embed;
    wednesday?: Day_company_admin_view_region_embed;
    thursday?: Day_company_admin_view_region_embed;
    friday?: Day_company_admin_view_region_embed;
    saturday?: Day_company_admin_view_region_embed;
    sunday?: Day_company_admin_view_region_embed;
    remarks?: string;
};

export namespace OpeningTimes_company_admin_view_region_embed {

    export enum type {
        NONE = 'none',
        ALWAYS_OPEN = 'always_open',
        SPECIFIC = 'specific',
    }


}

