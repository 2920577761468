/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Offer_offer_update = {
    title?: string;
    campaignTitle?: string;
    startDate?: string | null;
    expirationDate?: string | null;
    url?: string;
    categories?: Array<string>;
    images?: Array<string> | null;
    description?: string;
    terms?: string;
    upsell?: Offer_offer_update.upsell;
    readonly primaryImage?: string | null;
};

export namespace Offer_offer_update {

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_ADVICE = 'call_advice',
    }


}

