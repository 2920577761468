/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Day_jsonld_company_admin_view } from './Day_jsonld_company_admin_view';

export type OpeningTimes_jsonld_company_admin_view = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    type?: OpeningTimes_jsonld_company_admin_view.type;
    monday?: Day_jsonld_company_admin_view;
    tuesday?: Day_jsonld_company_admin_view;
    wednesday?: Day_jsonld_company_admin_view;
    thursday?: Day_jsonld_company_admin_view;
    friday?: Day_jsonld_company_admin_view;
    saturday?: Day_jsonld_company_admin_view;
    sunday?: Day_jsonld_company_admin_view;
    remarks?: string;
};

export namespace OpeningTimes_jsonld_company_admin_view {

    export enum type {
        NONE = 'none',
        ALWAYS_OPEN = 'always_open',
        SPECIFIC = 'specific',
    }


}

