/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company_company_list } from '../models/Company_company_list';
import type { Company_company_update } from '../models/Company_company_update';
import type { Company_company_view } from '../models/Company_company_view';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyService {

    /**
     * Retrieves the collection of Company resources.
     * Retrieves the collection of Company resources.
     * @returns Company_company_list Company collection
     * @throws ApiError
     */
    public static getCompanyCollection({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<Company_company_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }

    /**
     * Retrieves a Company resource.
     * Retrieves a Company resource.
     * @returns Company_company_view Company resource
     * @throws ApiError
     */
    public static getCompanyItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<Company_company_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Company resource.
     * Replaces the Company resource.
     * @returns Company_company_view Company resource updated
     * @throws ApiError
     */
    public static putCompanyItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated Company resource
         */
        requestBody: Company_company_update,
    }): CancelablePromise<Company_company_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Confirm company
     * Set status of company to confirmed, so it can be reviewed by the redaction
     * @returns Company_company_view Company resource updated
     * @throws ApiError
     */
    public static confirmCompanyItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated Company resource
         */
        requestBody: Company_company_update,
    }): CancelablePromise<Company_company_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{uuid}/confirm',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

}
