/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Offer_offer_create } from '../models/Offer_offer_create';
import type { Offer_offer_list } from '../models/Offer_offer_list';
import type { Offer_offer_update } from '../models/Offer_offer_update';
import type { Offer_offer_view } from '../models/Offer_offer_view';
import type { Offer_offer_view_category_embed } from '../models/Offer_offer_view_category_embed';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OfferService {

    /**
     * Retrieves the collection of Offer resources.
     * Retrieves the collection of Offer resources.
     * @returns Offer_offer_list Offer collection
     * @throws ApiError
     */
    public static getOfferCollection({
        page = 1,
        itemsPerPage = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<Offer_offer_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/offers',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
        });
    }

    /**
     * Creates a Offer resource.
     * Creates a Offer resource.
     * @returns Offer_offer_view_category_embed Offer resource created
     * @throws ApiError
     */
    public static postOfferCollection({
        requestBody,
    }: {
        /**
         * The new Offer resource
         */
        requestBody: Offer_offer_create,
    }): CancelablePromise<Offer_offer_view_category_embed> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/offers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Create and confirm offer
     * Set status of offer to confirmed, so it can be reviewed by the redaction
     * @returns Offer_offer_view Offer resource created
     * @throws ApiError
     */
    public static confirmOfferCollection({
        requestBody,
    }: {
        /**
         * The new Offer resource
         */
        requestBody: Offer_offer_create,
    }): CancelablePromise<Offer_offer_view> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/offers/confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Offer resource.
     * Retrieves a Offer resource.
     * @returns Offer_offer_view_category_embed Offer resource
     * @throws ApiError
     */
    public static getOfferItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<Offer_offer_view_category_embed> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/offers/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Offer resource.
     * Replaces the Offer resource.
     * @returns Offer_offer_view_category_embed Offer resource updated
     * @throws ApiError
     */
    public static putOfferItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated Offer resource
         */
        requestBody: Offer_offer_update,
    }): CancelablePromise<Offer_offer_view_category_embed> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offers/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Confirm offer
     * Set status of offer to confirmed, so it can be reviewed by the redaction
     * @returns Offer_offer_view_category_embed Offer resource updated
     * @throws ApiError
     */
    public static confirmOfferItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated Offer resource
         */
        requestBody: Offer_offer_update,
    }): CancelablePromise<Offer_offer_view_category_embed> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offers/{uuid}/confirm',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retract offer
     * Retract offer and unpublish from aagje
     * @returns Offer_offer_view_category_embed Offer resource updated
     * @throws ApiError
     */
    public static retractOfferItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated Offer resource
         */
        requestBody: any,
    }): CancelablePromise<Offer_offer_view_category_embed> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offers/{uuid}/retract',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

}
