/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CalendarEvent_jsonld_calendar_event_list = {
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly uuid?: string;
    title?: string;
    startDate?: string | null;
    endDate?: string | null;
    status?: CalendarEvent_jsonld_calendar_event_list.status;
    readonly primaryImage?: string | null;
};

export namespace CalendarEvent_jsonld_calendar_event_list {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }


}

