/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEvent_calendar_event_create } from '../models/CalendarEvent_calendar_event_create';
import type { CalendarEvent_calendar_event_list } from '../models/CalendarEvent_calendar_event_list';
import type { CalendarEvent_calendar_event_update } from '../models/CalendarEvent_calendar_event_update';
import type { CalendarEvent_calendar_event_view } from '../models/CalendarEvent_calendar_event_view';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalendarEventService {

    /**
     * Retrieves the collection of CalendarEvent resources.
     * Retrieves the collection of CalendarEvent resources.
     * @returns CalendarEvent_calendar_event_list CalendarEvent collection
     * @throws ApiError
     */
    public static getCalendarEventCollection({
        page = 1,
        itemsPerPage = 30,
        orderTitle,
        orderStartDate,
        orderEndDate,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        orderTitle?: 'asc' | 'desc',
        orderStartDate?: 'asc' | 'desc',
        orderEndDate?: 'asc' | 'desc',
    }): CancelablePromise<Array<CalendarEvent_calendar_event_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calendar_events',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'order[title]': orderTitle,
                'order[startDate]': orderStartDate,
                'order[endDate]': orderEndDate,
            },
        });
    }

    /**
     * Creates a CalendarEvent resource.
     * Creates a CalendarEvent resource.
     * @returns CalendarEvent_calendar_event_view CalendarEvent resource created
     * @throws ApiError
     */
    public static postCalendarEventCollection({
        requestBody,
    }: {
        /**
         * The new CalendarEvent resource
         */
        requestBody: CalendarEvent_calendar_event_create,
    }): CancelablePromise<CalendarEvent_calendar_event_view> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/calendar_events',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Create and confirm calendar event
     * Create a event and set status to confirmed, so it can be reviewed by the redaction
     * @returns CalendarEvent_calendar_event_view CalendarEvent resource created
     * @throws ApiError
     */
    public static confirmCalendarEventCollection({
        requestBody,
    }: {
        /**
         * The new CalendarEvent resource
         */
        requestBody: CalendarEvent_calendar_event_update,
    }): CancelablePromise<CalendarEvent_calendar_event_view> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/calendar_events/confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a CalendarEvent resource.
     * Retrieves a CalendarEvent resource.
     * @returns CalendarEvent_calendar_event_view CalendarEvent resource
     * @throws ApiError
     */
    public static getCalendarEventItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<CalendarEvent_calendar_event_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calendar_events/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the CalendarEvent resource.
     * Replaces the CalendarEvent resource.
     * @returns CalendarEvent_calendar_event_view CalendarEvent resource updated
     * @throws ApiError
     */
    public static putCalendarEventItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated CalendarEvent resource
         */
        requestBody: CalendarEvent_calendar_event_update,
    }): CancelablePromise<CalendarEvent_calendar_event_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/calendar_events/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Update and confirm event
     * Update & set status of the event of event to confirmed, so it can be reviewed by the redaction
     * @returns CalendarEvent_calendar_event_view CalendarEvent resource updated
     * @throws ApiError
     */
    public static confirmCalendarEventItem({
        uuid,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
        /**
         * The updated CalendarEvent resource
         */
        requestBody: CalendarEvent_calendar_event_update,
    }): CancelablePromise<CalendarEvent_calendar_event_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/calendar_events/{uuid}/confirm',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

}
