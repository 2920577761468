/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable unicorn/prefer-module */
import './global.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
config.replacementClass = '';

import { ActiveCompanyProvider } from 'hooks/ActiveCompanyProvider';
import { ApiError, Company_company_list, SessionLogin } from 'generated/api';
import { captureException } from 'utils/captureException';
import { getUser as apiGetUser, login as apiLogin, logout as apiLogout } from 'utils/api';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { setLocale } from 'yup';
import { UserContext, UserContextType } from 'hooks/UserContext';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { AppProps } from 'next/app';

setLocale({
  string: {
    email: 'Dit veld moet een geldig e-mailadres bevatten',
    url: 'Dit veld moet een geldige url bevatten',
    max: 'Dit veld mag maximaal ${max} karakters bevatten',
    min: 'Dit veld moet minimaal ${min} karakters bevatten',
  },
  mixed: {
    required: 'Dit veld is verplicht',
  },
});

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, { trackAllPureComponents: true });
}

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const [activeCompanyUuid, setActiveCompanyUuid] = useState<Company_company_list['uuid']>();
  const [isLoadingUser, setIsLoadingUser] = useState<UserContextType['isLoadingUser']>(true);
  const [user, setUser] = useState<UserContextType['user']>(undefined);

  const didFetchUserData = useRef(false);

  const getUserData = useCallback(async () => {
    try {
      setIsLoadingUser(true);
      const userData = await apiGetUser();
      setUser(userData);
      return userData;
    } catch (error) {
      captureException(error, { tags: { source: 'UserProvider/getUserData' } });
    } finally {
      setIsLoadingUser(false);
    }
  }, []);

  useEffect(() => {
    if (!didFetchUserData.current) {
      getUserData();
      didFetchUserData.current = true;
    }
  }, [getUserData]);

  const login = useCallback(
    async (values: SessionLogin) => {
      await apiLogin(values);
      return await getUserData();
    },
    [getUserData]
  );

  const logoutState = useCallback(() => {
    setActiveCompanyUuid(undefined);
    setIsLoadingUser(false);
    setUser(undefined);
  }, []);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error) => {
              const isUnauthorizedError = error instanceof ApiError && error.status === 401;
              return !isUnauthorizedError && failureCount <= 1;
            },
          },
        },
        queryCache: new QueryCache({
          onError: async (error, query) => {
            if (error instanceof ApiError && error.status === 401) {
              await apiLogout();
              queryClient.clear();
              logoutState();
            } else {
              captureException(error, {
                tags: { source: 'queryCache', queryHash: query.queryHash, queryKey: JSON.stringify(query.queryKey) },
              });
            }
          },
        }),
      }),
    [logoutState]
  );

  const logout = useCallback(async () => {
    await apiLogout();
    queryClient.clear();
    logoutState();
  }, [logoutState, queryClient]);

  return (
    <UserContext.Provider value={{ isAdmin: user?.admin, isLoadingUser, login, logout, user }}>
      <QueryClientProvider client={queryClient}>
        <ActiveCompanyProvider activeCompanyUuid={activeCompanyUuid} setActiveCompanyUuid={setActiveCompanyUuid}>
          <Component {...pageProps} />
        </ActiveCompanyProvider>
      </QueryClientProvider>
    </UserContext.Provider>
  );
};

export default MyApp;
