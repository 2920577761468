export interface PaginationData {
  currentPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemsPerPage: number;
  lastPage: number;
  totalItems: number;
}

const getPaginationHeader = (key: string, response: Response) => Number.parseInt(response.headers.get(key) || '0', 10);

export const getPaginationDataFromResponseHeaders = (response: Response): PaginationData | undefined => {
  if (!response?.headers) {
    return;
  }
  return {
    currentPage: getPaginationHeader('x-pagination-current-page', response),
    hasNextPage: getPaginationHeader('x-pagination-has-next-page', response) === 1,
    hasPreviousPage: getPaginationHeader('x-pagination-has-previous-page', response) === 1,
    itemsPerPage: getPaginationHeader('x-pagination-items-per-page', response),
    lastPage: getPaginationHeader('x-pagination-last-page', response),
    totalItems: getPaginationHeader('x-pagination-total-items', response),
  };
};
