/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Day_company_update } from './Day_company_update';

export type OpeningTimes_company_update = {
    type?: OpeningTimes_company_update.type;
    monday?: Day_company_update;
    tuesday?: Day_company_update;
    wednesday?: Day_company_update;
    thursday?: Day_company_update;
    friday?: Day_company_update;
    saturday?: Day_company_update;
    sunday?: Day_company_update;
    remarks?: string;
};

export namespace OpeningTimes_company_update {

    export enum type {
        NONE = 'none',
        ALWAYS_OPEN = 'always_open',
        SPECIFIC = 'specific',
    }


}

