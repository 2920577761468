/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Day_offer_admin_view_category_embed } from './Day_offer_admin_view_category_embed';

export type OpeningTimes_offer_admin_view_category_embed = {
    type?: OpeningTimes_offer_admin_view_category_embed.type;
    monday?: Day_offer_admin_view_category_embed;
    tuesday?: Day_offer_admin_view_category_embed;
    wednesday?: Day_offer_admin_view_category_embed;
    thursday?: Day_offer_admin_view_category_embed;
    friday?: Day_offer_admin_view_category_embed;
    saturday?: Day_offer_admin_view_category_embed;
    sunday?: Day_offer_admin_view_category_embed;
    remarks?: string;
};

export namespace OpeningTimes_offer_admin_view_category_embed {

    export enum type {
        NONE = 'none',
        ALWAYS_OPEN = 'always_open',
        SPECIFIC = 'specific',
    }


}

