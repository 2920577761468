import { createContext, useContext } from 'react';
import { SessionLogin, User_user_view_user_view_self } from 'generated/api';

export interface UserContextType {
  isAdmin?: boolean;
  isLoadingUser: boolean;
  login: (values: SessionLogin) => Promise<User_user_view_user_view_self | undefined>;
  logout: () => Promise<void>;
  user?: User_user_view_user_view_self;
}

const initialState = {
  isAdmin: false,
  isLoadingUser: true,
  // eslint-disable-next-line unicorn/no-useless-undefined
  login: async () => undefined,
  logout: async () => {
    // init
  },
  user: undefined,
};

export const UserContext = createContext<UserContextType>(initialState);

export const useUser = () => useContext(UserContext);
