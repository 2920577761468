/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Day_company_admin_view } from './Day_company_admin_view';

export type OpeningTimes_company_admin_view = {
    type?: OpeningTimes_company_admin_view.type;
    monday?: Day_company_admin_view;
    tuesday?: Day_company_admin_view;
    wednesday?: Day_company_admin_view;
    thursday?: Day_company_admin_view;
    friday?: Day_company_admin_view;
    saturday?: Day_company_admin_view;
    sunday?: Day_company_admin_view;
    remarks?: string;
};

export namespace OpeningTimes_company_admin_view {

    export enum type {
        NONE = 'none',
        ALWAYS_OPEN = 'always_open',
        SPECIFIC = 'specific',
    }


}

