/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionButton_offer_admin_view_category_embed } from './ActionButton_offer_admin_view_category_embed';
import type { CompanyCategory_offer_admin_view_category_embed } from './CompanyCategory_offer_admin_view_category_embed';
import type { KnownFor_offer_admin_view_category_embed } from './KnownFor_offer_admin_view_category_embed';
import type { OpeningTimes_offer_admin_view_category_embed } from './OpeningTimes_offer_admin_view_category_embed';
import type { User_offer_admin_view_category_embed } from './User_offer_admin_view_category_embed';

export type Company_offer_admin_view_category_embed = {
    readonly uuid?: string;
    aagjeId?: number | null;
    status?: Company_offer_admin_view_category_embed.status;
    address?: string;
    zipCode?: string;
    images?: Array<string>;
    description?: string;
    knownFor?: KnownFor_offer_admin_view_category_embed;
    oneLiner?: string;
    openingTimes?: OpeningTimes_offer_admin_view_category_embed;
    actionButtons?: Array<ActionButton_offer_admin_view_category_embed>;
    website?: string;
    facebook?: string;
    instagram?: string;
    linkedIn?: string;
    pinterest?: string;
    twitter?: string;
    youtube?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;
    upsell?: Company_offer_admin_view_category_embed.upsell;
    acceptedAt?: string | null;
    acceptedBy?: User_offer_admin_view_category_embed | null;
    categories?: Array<CompanyCategory_offer_admin_view_category_embed>;
    name?: string;
    user?: User_offer_admin_view_category_embed | null;
    region?: string;
    location?: string;
    readonly primaryImage?: string | null;
    readonly profileEmpty?: boolean;
};

export namespace Company_offer_admin_view_category_embed {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_UPSELL = 'call_upsell',
        CALL_ADVICE = 'call_advice',
    }


}

